.wishlist-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content:space-around;

}

.item-card {
  flex: 1 1 230px; /* Set the width of the card to 250px */
  max-width: 230px; /* Ensure the card does not exceed 250px */
  box-sizing: border-box;
  margin-bottom: 16px; /* Optional: Add margin to the bottom for spacing */
  text-decoration: none; /* Remove underline from links */
}

.item-card.clickable {
  border: 2px solid blue; /* Add a border if the card is clickable */
}

.item-card .card-link {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color */
  display: block; /* Make the link cover the entire card */
}

.item-image {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  object-position: top;
}

.item-placeholder {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  background-image: url('../../../assets/appheader.png');
  background-size: cover;
  background-position: center;
  
}
.image-container {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}
.item-details {
  padding-left: 10px;
}

.item-state {
  padding-right: 10px;
}

.delete-button,
.promise-button {
  visibility: hidden;
  position: absolute;
  bottom: 10px; /* Adjust the distance from the bottom as needed */
  right:10px;
  transform: translateX(0);
}

.item-card:hover .delete-button,
.item-card:hover .promise-button {
  visibility: visible;
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .item-card {
    width: 100%; /* Set the width of the card to 100% */
    flex: 1 1 100%; /* Ensure flexbox respects the 100% width */
    max-width: none;;
  }
}
.card-body {
  padding-bottom: 40px; /* Add padding to ensure space for the button */
}
