.metadata-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .metadata-image {
    max-height: 400px;
    width: auto;
    margin: 10px;
  }
  .is-invalid {
    border-color: red;
  }
  