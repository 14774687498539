.container {
  max-width: 500px;
  margin: 0 auto;
}

.formGroup {
  padding-bottom: 20px;
}

.headerImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.input{
  margin:10px;
  border-radius: 5px;
  border:1px solid #ccc;
}
