html, body {
  height:100%;
  margin:0;
}
.custom-navbar {
  height: 100px;
  background-color: #ebdcc7;
  color: white;
}

.container {
  min-height: 100vh;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
}
.content{
  flex: 1;
}
.footer{
  text-align: center;
  padding: 10px;
  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
}

.navbar-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}

.addButton {
  padding: 1px 10px;
  margin-left: 10px;
}

.shareButton {
  padding: 1px 10px;
  margin-right: 10px;
}
.settingsButton {
  padding: 1px 10px;
  margin-right: 10px;
}
.shareAlert {
  position: fixed;
  top: 0;
  right: 0;
  margin: 1rem;
  z-index: 9999;
}

.shareAlert.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.shareAlert.show {
  opacity: 1;
  transition: opacity 0.15s linear;
}

.wishlist {
  flex: 1;
}
.empty {
  padding-left: 10px;
  padding-right: 10px;
}

.formControl {
  margin-bottom: 10px;
}

.button {
  margin-left: 10px;
  float: right;
}
.wishlist-items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Adjust the gap between items as needed */
}

.item-card {
  flex: 1 1 calc(33.333% - 16px); /* Adjust the width and gap as needed */
  box-sizing: border-box;
}
.loader-container {
  position: fixed; /* Position the loader container fixed within the viewport */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the loader */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loader is above other content */
}